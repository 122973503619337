<template>
  <div class="content">
    <router-header :tabs="$tabs.operationTabs" v-if="hasResource('operation_management')"></router-header>
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive" class="mg-b-45 mg-t-72"></router-view>
    </keep-alive>
    <router-view class="mg-b-45 mg-t-72" v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>
<script>
import routerHeader from "@/components/router-header";

export default {
  components: { RouterHeader: routerHeader },
};
</script>
